import { GetAppointmentsParams, APIResponse } from '../types';
import { getToken } from './auth';
import { sanitizeObject, setDefaultsForPagination } from './_helpers';
const BASE_URL = '/api';

/**
 * @param {GetAppointmentsParams} params 
 * @returns {Promise<APIResponse>}
 */
export async function getAppointments (params) {
  try {
    setDefaultsForPagination(params)
    sanitizeObject(params, GetAppointmentsParams)
    
    const token = await getToken();
    const parameters = new URLSearchParams(params).toString();

    const response = await fetch(`${BASE_URL}/appointment?${parameters}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar visitas');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar visitas:', error);
    return { data: [], links: { metaData: { total: 0, lastPage: 0 } } };
  }
}


/**
 * @param {GetAppointmentsParams} params 
 * @returns {Promise<string>}
 */
export const downloadAppointmentsCSV = async (params) => {
  try {
    setDefaultsForPagination(params)
    sanitizeObject(params, GetAppointmentsParams)

    const token = await getToken();
    const parameters = new URLSearchParams(params).toString();

    const response = await fetch(`${BASE_URL}/appointment/csv?${parameters}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar visitas');
    }

    const result = await response.text();
    return result;
  } catch (error) {
    console.error('Erro ao buscar visitas:', error);
    return { data: [], links: { metaData: { total: 0, lastPage: 0 } } };
  }
}

/**
 * 
 * @param {uuid} AppointmentId 
 * @returns {Promise<APIResponse>}
 */
export const getAppointmentById = async (AppointmentId) => {
  try {
    const token = await getToken();

    const response = await fetch(`${BASE_URL}/appointment/${AppointmentId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar visita');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar visita:', error);
    return { result: null };
  };
};


/**
 * 
 * @param {uuid} AppointmentId 
 * @returns {Promise<APIResponse>}
 */
export const getAttestById = async (AppointmentId) => {
  try {
    const token = await getToken();

    const response = await fetch(`${BASE_URL}/appointment/${AppointmentId}/attestment`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar registro de visita');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar registro de visita:', error);
    return { result: null };
  };
};

export const fetchAttestPhoto = async (photoFileName) => {
  try {
    const token = await getToken();
    const response = await fetch(`${BASE_URL}/storage/attestment-photos/${photoFileName}`, {
      method: 'GET',
      headers: {
        'Authorization': `${token}`,
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      return url;
    } else {
      console.error('Erro ao buscar foto do registro de visita');
      return null;
    }
  } catch (error) {
    console.error('Erro ao buscar foto do registro de visita', error);
    return null;
  }
};

/**
 * @returns {Promise<APIResponse>}
 */
export const getAppointmentReferences = async () => {
  try {
    const token = await getToken();
    const response = await fetch(`${BASE_URL}/appointment/reference`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar dados de referência para visitas');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar dados de referência para visitas:', error);
    return { data: [], links: { metaData: { total: 0, lastPage: 0 } } };
  }
}